@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&display=swap');
*{
  box-sizing: content-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
}
.App {
  text-align: center;
  background-color: #F8F8F8;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.titles{
  font-family: "Belanosima", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.css-57i9nt {
  margin-top: 0.5rem !important;
}

.css-1u2cvaz{
  width: 100% !important;
  padding: 1rem !important;
  top: 20% !important;
}


.css-3dtahj{
  border-radius: "12px" !important;
  border-color: "none" !important;
}
.css-bh4wo8 {
  border-radius: 24px;
}
@keyframes slideRight {
  to {
    transform: translateX(0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(202, 0, 23, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(202, 0, 23, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(202, 0, 23, 0);
  }
}

.pulse-button {
  animation: pulse 2s infinite;
}
.containerServ{
  height: 100dvh;
  padding-top: 3rem;
}
.spinning-gear {
  animation: rotate 2s linear infinite;
  margin: auto;
}

.text-container {
  margin-top: 2rem;

}

.text-container h2 {
  color: #2d3748;
  margin-bottom: 1rem;
  pad: 1rem;
}

.text-container p {
  color: #718096;
  line-height: 1.5;
  padding: 1rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}