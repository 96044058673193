/* ---------------------HERO--------------------- */
/* Hero container */
/* Hero container */
.contHero {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  padding: 5%;
}
.meat-quality-card {
  display: flex;
  width: 100%;
  height: 45dvh;
  overflow: hidden;
  border-radius: 24px;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  gap: 5%;
}

.meat-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.overlayHero {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleHero {
  font-size: 64px;
  /* font-weight: bold; */
  text-align: start;
  line-height: 1.2 ;
  font-weight: 400;
  margin-bottom: 1rem ;
  font-family: "Belanosima", sans-serif;
}
.titles{
  font-family: "Belanosima", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.textRelleno{
    font-size: 18px;
    text-wrap: balance;
}
.cta-button {
  width: 100%;
  height: 15%;
  padding: 10px;
  background-color: #ca0017;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.cta-button:hover {
  background-color: #c53030;
}
.contInfoHero {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between
}
.contInfoHero > div {
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  gap: 1rem;
}
.contInfoHero > div > div:first-child {
  max-width: 30%;
}

.infoFirstText > h2 {
  font-size: 1.2rem;
  font-family: "Belanosima", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.infoFirstText > p {
  font-size: 12px;
  font-family: "Belanosima", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* ------------------------CARD MAS VENDIDO ------------------ */
.card {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  height: 296px;
  min-width: 162px;
  max-width: 220px;
  background-color: #FFFFFF;
  border-radius: 24px;
  justify-content: space-between;
  box-shadow: inset 0 0 0 1px #EFEFEF; /* Borde interior */
}


.image_container {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 132px;
  border-radius: 12px;
}

.title {
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin-bottom: "3rem";
}

.size {
  font-size: 0.75rem;
  color: var(--light);
}

.list-size {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.list-size .item-list {
  list-style: none;
}

.list-size .item-list-button {
  cursor: pointer;
  padding: 0.5rem;
  background-color: var(--zinc-800);
  font-size: 0.75rem;
  color: var(--light);
  border: 2px solid var(--zinc-800);
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
}

.item-list-button:hover {
  border: 2px solid var(--light);
}

.item-list-button:focus {
  background-color: var(--primary);
  border: 2px solid var(--primary-shadow);
}

.action {
  border-radius: 12px;
}

.price {
  font-size: 1rem;
  font-weight: 700;
  color: black;
  text-align: start;
}

.cart-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  background-color: #ca0017;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--light);
  white-space: nowrap;
  border-radius: 24px;
}

.cart-button .cart-icon {
  width: 1rem;
}

.react-parallax-content {
  height: 70vh;
}
.css-w21w7o {
  height: 100%;
}

/* Static background for mobile devices */
@media (max-width: 768px) {
  .hero-container {
    background-attachment: scroll;
  }
  .react-parallax-content {
    height: auto;
  }
  .contInfoHero {
    flex-direction: column;
    gap: .5rem;
  }



  .textRelleno{
    display: none;
  }


  .contHero {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .meat-quality-card {
    position: relative;
    width: 100%;
    height: 50dvh;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .meat-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .overlayHero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }
  
  .titleHero {
    color: white;
    font-size: 40px;
    text-align: start;
    margin-bottom: 8px;
  }
}
